import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { ADD_SUBSCRIPTION_ENDPONT } from "../../api";
import { getAllFeatures } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import { Card1 } from "../../components/commoncomps/Card1";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";

const AddSubscription = () => {
  const dispatch = useDispatch();

  // contant to use useTranslation
  const { t } = useTranslation();

  // get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  const [submitSubscriptionResponse, setSubmitSubscriptionResponse] = useState();
  const [loader, setLoader] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([{ feature_code: "", quantity: "" }]);

  const [addSubscriptionPayload, setAddSubscriptionPayload] = useState({
    title: "",
    description: "",
    price_inr: "",
    sale_price_inr: "",
    price_usd: "",
    sale_price_usd: "",
    price_pond: "",
    sale_price_pond: "",
    price_euro: "",
    sale_price_euro: "",
    validity: "",
    features: selectedFeatures,
  });

  // getting all features
  useEffect(() => {
    if (allFeatures.length == 0) {
      dispatch(getAllFeatures());
    }
  }, []);

  // get all features from admin state
  const {
    featuresDetail: { allFeatures },
  } = useSelector((state) => state.admin);

  // get user permission from admin state
  const { userPermission } = useSelector((state) => state.admin);

  // add feature in subscription payload
  const addFeature = () => {
    let featureCounterLength = selectedFeatures.length;
    let allFeaturesLength = allFeatures.length;
    // check if the last feature is empty or not
    if (
      featureCounterLength >= allFeaturesLength ||
      selectedFeatures[featureCounterLength - 1].feature_code === "" ||
      selectedFeatures[featureCounterLength - 1].quantity === ""
    )
      return;

    const featureObject = {
      feature_code: "",
      quantity: "",
    };
    let updatedSelectedFeature = [...selectedFeatures];
    updatedSelectedFeature.push(featureObject);
    setSelectedFeatures(updatedSelectedFeature);
    setAddSubscriptionPayload({ ...addSubscriptionPayload, features: updatedSelectedFeature });
  };

  // check if any of the input field is empty
  const isDisabled = !!(
    addSubscriptionPayload.title.length === 0 ||
    addSubscriptionPayload.price_inr.length === 0 ||
    addSubscriptionPayload.validity.length === 0 ||
    selectedFeatures.length === 0
  );

  // submitting the subscription to backend
  const submitSubscription = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.post(ADD_SUBSCRIPTION_ENDPONT, addSubscriptionPayload);
      setSubmitSubscriptionResponse(resp);
      if (resp) setLoader(false);
    } catch (error) {
      setSubmitSubscriptionResponse(error?.response);
      setLoader(false);
    }
  };

  // check the user permission
  if (!userPermission.includes("create_subscription") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }

  return (
    <div>
      <DashboardWrapper>
        <div>
          <h2 align="center">{t("addSubscription")}</h2>
        </div>
        <Card1>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("name")}:
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_name"
              type="text"
              placeholder={t("subscription") + " " + t("name")}
              onChange={(e) =>
                setAddSubscriptionPayload({ ...addSubscriptionPayload, title: e.target.value })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_inr"
              placeholder={t("placeholderPrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({ ...addSubscriptionPayload, price_inr: e.target.value })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")} (INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_sale_price_inr"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({
                  ...addSubscriptionPayload,
                  sale_price_inr: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(USD):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_usd"
              placeholder={t("placeholderPrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({ ...addSubscriptionPayload, price_usd: e.target.value })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(USD):
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_sale_price_usd"
              type="number"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({
                  ...addSubscriptionPayload,
                  sale_price_usd: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(GBP):
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_price_gbp"
              type="number"
              placeholder={t("placeholderPrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({ ...addSubscriptionPayload, price_pond: e.target.value })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")} (GBP):
            </label>
            <InputField
              className="cd-input-field"
              name="cd_subscription_sale_price_gbp"
              type="number"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({
                  ...addSubscriptionPayload,
                  sale_price_pond: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_eur"
              placeholder={t("placeholderPrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({ ...addSubscriptionPayload, price_euro: e.target.value })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_sale_price_gbp"
              placeholder={t("placeholderSalePrice")}
              onChange={(e) =>
                setAddSubscriptionPayload({
                  ...addSubscriptionPayload,
                  sale_price_euro: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("validity")}(In Days):
            </label>
            <InputField
              className="cd-input-field"
              min="0"
              type="number"
              name="cd_subscription_validity"
              placeholder={t("placeholderValidity")}
              onChange={(e) =>
                setAddSubscriptionPayload({ ...addSubscriptionPayload, validity: e.target.value })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("details")}:
            </label>
            <TextArea
              className="cd_input_textarea"
              cols="24"
              rows="10"
              name="cd_subscription_desc"
              onChange={(e) =>
                setAddSubscriptionPayload({
                  ...addSubscriptionPayload,
                  description: e.target.value,
                })
              }
            ></TextArea>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("feature")}:
              {selectedFeatures.length < allFeatures.length && (
                <Button onClick={addFeature} className="cd-btn">
                  +
                </Button>
              )}
            </label>
            {selectedFeatures?.map((feature, index) => (
              <div key={index} className="cd-features-list-selector clearfix">
                <select
                  className="cd-features-list-selector-left cd-input-field"
                  name="features_select"
                  value={feature.feature_code}
                  id=""
                  onChange={(e) => {
                    setSelectedFeatures((prev) => {
                      const newArr = [...prev];
                      newArr[index].feature_code = e.target.value;
                      return newArr;
                    });
                  }}
                >
                  <option value="">{t("selectFeature")}</option>
                  {allFeatures.map((feature, index) => (
                    <option key={index} value={feature.feature_code}>
                      {feature?.feature_type}
                    </option>
                  ))}
                </select>
                <InputField
                  className="cd-input-field cd-features-list-selector-right"
                  type="number"
                  name="cd_feature_quantity"
                  placeholder="Quantity"
                  value={feature.quantity}
                  min="1"
                  onChange={(e) =>
                    setSelectedFeatures((prev) => {
                      const tempArr = [...prev];
                      tempArr[index].quantity = e.target.value;
                      return tempArr;
                    })
                  }
                ></InputField>
              </div>
            ))}
          </div>
          {loader && <Loader />}
          {submitSubscriptionResponse?.status == 201 && (
            <div className="cd-success-msg">{t("subscriptionAddedMsg")}</div>
          )}
          {submitSubscriptionResponse?.data?.detail && (
            <div className="">
              <div className="cd-err-msg">{submitSubscriptionResponse?.data?.detail[0]?.msg}</div>
            </div>
          )}
          {submitSubscriptionResponse?.data?.detail?.detail && (
            <div className="">
              <div className="cd-err-msg">
                {submitSubscriptionResponse?.data?.detail?.detail[0]?.msg}
              </div>
            </div>
          )}
          <div>
            <Button
              className="cd-btn"
              onClick={submitSubscription}
              disabled={isDisabled}
              title={isDisabled ? "Please fill all inputs to enable" : "Submit"}
            >
              Add Subscription
            </Button>
          </div>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default AddSubscription;
