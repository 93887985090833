import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axiosFetch from "../../axiosConfig";
import { UPDATE_SUBSCRIPTION_ENDPONT, GET_SUBSCRIPTION_DETAIL } from "../../api";
import { getAllFeatures } from "../../redux/slice/backendUserSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import { Card1 } from "../../components/commoncomps/Card1";
import InputField from "../../components/inputs/InputField";
import TextArea from "../../components/inputs/TextArea";
import Button from "../../components/buttons/Button";
import Loader from "../../components/loaders/Loader";

const AddSubscription = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { suid } = useParams();
  const [subscriptionDetail, setSubscriptionDetail] = useState("");
  const [updateSubscriptionResponse, setupdateSubscriptionResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [updateSubscriptionPayload, setUpdateSubscriptionPayload] = useState({
    suid,
    title: "",
    description: "",
    price_inr: "",
    sale_price_inr: "",
    price_usd: "",
    sale_price_usd: "",
    price_pond: "",
    sale_price_pond: "",
    price_euro: "",
    sale_price_euro: "",
    validity: "",
    is_deleted: false,
    features: selectedFeatures,
  });

  // constant to use useTranslation hook
  const { t } = useTranslation();

  // get user from localstorage
  const user = JSON.parse(localStorage.getItem("user"));

  // getting the subscription detail
  useEffect(() => {
    setProgress(40);
    const getSubscriptionDetail = async () => {
      try {
        const resp = await axiosFetch.get(GET_SUBSCRIPTION_DETAIL + suid);
        setSubscriptionDetail(resp);
        setProgress(100);
      } catch (error) {
        setSubscriptionDetail(error?.response);
        setProgress(100);
      }
    };
    getSubscriptionDetail();
  }, []);

  // initializing all the subscription detail information in variables
  const tempSelectedFeatures = useMemo(() => {
    return subscriptionDetail?.data?.features?.map((data) => {
      return {
        feature_code: data?.feature?.feature_code,
        quantity: data?.quantity,
      };
    });
  }, [subscriptionDetail]);
  useEffect(() => {
    let subscriptionData = {
      suid: suid,
      title: subscriptionDetail?.data?.title,
      description: subscriptionDetail?.data?.description,
      price_inr: subscriptionDetail?.data?.price_inr,
      sale_price_inr: subscriptionDetail?.data?.sale_price_inr,
      price_usd: subscriptionDetail?.data?.price_usd,
      sale_price_usd: subscriptionDetail?.data?.sale_price_usd,
      price_pond: subscriptionDetail?.data?.price_pond,
      sale_price_pond: subscriptionDetail?.data?.sale_price_pond,
      price_euro: subscriptionDetail?.data?.price_euro,
      sale_price_euro: subscriptionDetail?.data?.sale_price_euro,
      validity: subscriptionDetail?.data?.validity,
      is_deleted: false,
      features: tempSelectedFeatures,
    };
    setUpdateSubscriptionPayload(subscriptionData);
    setSelectedFeatures(tempSelectedFeatures);
  }, [subscriptionDetail, tempSelectedFeatures]);

  // getting all features
  useEffect(() => {
    dispatch(getAllFeatures());
  }, []);
  const {
    featuresDetail: { allFeatures },
  } = useSelector((state) => state.admin);
  const { userPermission } = useSelector((state) => state.admin);

  const isDisabled =
    updateSubscriptionPayload?.title?.length === 0 ||
    updateSubscriptionPayload?.price_inr?.length === 0 ||
    updateSubscriptionPayload?.validity?.length === 0 ||
    updateSubscriptionPayload?.features?.length === 0;

  // submitting the subscription to backend
  const updateSubscription = async () => {
    setLoader(true);
    try {
      const resp = await axiosFetch.put(UPDATE_SUBSCRIPTION_ENDPONT, updateSubscriptionPayload);
      setupdateSubscriptionResponse(resp);
      if (resp.status === 200) {
        setLoader(false);
      }
    } catch (error) {
      setupdateSubscriptionResponse(error?.response);
      setLoader(false);
    }
  };

  // add feature in subscription payload
  const addFeature = () => {
    let featureCounterLength = selectedFeatures.length;
    let allFeaturesLength = allFeatures.length;
    // check if the last feature is empty or not
    if (
      featureCounterLength >= allFeaturesLength ||
      selectedFeatures[featureCounterLength - 1]?.feature_code === "" ||
      selectedFeatures[featureCounterLength - 1]?.quantity === ""
    )
      return;

    const featureObject = {
      feature_code: "",
      quantity: "",
    };
    let updatedSelectedFeature = [...selectedFeatures];
    updatedSelectedFeature.push(featureObject);
    setSelectedFeatures(updatedSelectedFeature);
    setUpdateSubscriptionPayload({
      ...updateSubscriptionPayload,
      features: updatedSelectedFeature,
    });
  };

  if (!userPermission.includes("update_subscription") && user?.role !== "superuser") {
    return (
      <DashboardWrapper>
        <div className="cd-err-msg">You don't have access to view this page</div>
      </DashboardWrapper>
    );
  }

  return (
    <div>
      <DashboardWrapper>
        <div>
          <h2 align="center">{t("updateSubscription")} </h2>
        </div>
        <Card1>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("name")}:
            </label>
            <InputField
              className="cd-input-field"
              type="text"
              name="cd_subscription_title"
              placeholder="Subscription Name"
              value={updateSubscriptionPayload.title}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  title: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              name="cd_subscription_price_inr"
              placeholder="Enter Price"
              value={updateSubscriptionPayload.price_inr}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  price_inr: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(INR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_inr"
              value={updateSubscriptionPayload.sale_price_inr}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  sale_price_inr: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")} (USD):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Price"
              name="cd_subscription_price_usd"
              value={updateSubscriptionPayload.price_usd}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  price_usd: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")} (USD):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_usd"
              value={updateSubscriptionPayload.sale_price_usd}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  sale_price_usd: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")} (GBP):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Price"
              name="cd_subscription_price_gbp"
              value={updateSubscriptionPayload.price_pond}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  price_pond: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {" "}
              {t("salePrice")} (GBP):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_gbp"
              value={updateSubscriptionPayload.sale_price_pond}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  sale_price_pond: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("price")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Price"
              name="cd_subscription_price_eur"
              value={updateSubscriptionPayload.price_euro}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  price_euro: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("salePrice")}(EUR):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Sale Price"
              name="cd_subscription_sale_price_gbp"
              value={updateSubscriptionPayload.sale_price_euro}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  sale_price_euro: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("validity")}(In Days):
            </label>
            <InputField
              className="cd-input-field"
              type="number"
              placeholder="Enter Validity"
              name="cd_subscription_validity"
              value={updateSubscriptionPayload.validity}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  validity: e.target.value,
                })
              }
            />
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("description")}:
            </label>
            <TextArea
              className="cd_input_textarea"
              cols="24"
              rows="10"
              name="cd_subscription_desc"
              value={updateSubscriptionPayload.description}
              onChange={(e) =>
                setUpdateSubscriptionPayload({
                  ...updateSubscriptionPayload,
                  description: e.target.value,
                })
              }
            >
              {updateSubscriptionPayload.description}
            </TextArea>
          </div>
          <div className="cd-input-container">
            <label className="cd-input-label" htmlFor="">
              {t("feature")}:
              {selectedFeatures && selectedFeatures.length < allFeatures.length && (
                <Button onClick={addFeature} className="cd-btn">
                  +
                </Button>
              )}
            </label>
            {selectedFeatures?.map((feature, index) => (
              <div key={index} className="cd-features-list-selector clearfix">
                <select
                  className="cd-features-list-selector-left cd-input-field"
                  name="features_select"
                  value={feature.feature_code}
                  id=""
                  onChange={(e) => {
                    setSelectedFeatures((prev) => {
                      const newArr = [...prev];
                      newArr[index].feature_code = e.target.value;
                      return newArr;
                    });
                  }}
                >
                  <option value="">{t("selectFeature")}</option>
                  {allFeatures.map((feature, index) => (
                    <option key={index} value={feature.feature_code}>
                      {feature?.feature_type}
                    </option>
                  ))}
                </select>
                <InputField
                  className="cd-input-field cd-features-list-selector-right"
                  type="number"
                  name="cd_feature_quantity"
                  placeholder="Quantity"
                  value={feature.quantity}
                  min="1"
                  onChange={(e) =>
                    setSelectedFeatures((prev) => {
                      const tempArr = [...prev];
                      tempArr[index].quantity = e.target.value;
                      return tempArr;
                    })
                  }
                ></InputField>
              </div>
            ))}
          </div>
          {loader && <Loader />}
          {updateSubscriptionResponse?.status == 200 && (
            <div className="cd-success-msg">{t("subscriptionUpdatedMsg")}</div>
          )}
          {updateSubscriptionResponse?.data?.detail && (
            <div className="">
              <div className="cd-err-msg">{updateSubscriptionResponse?.data?.detail[0]?.msg}</div>
            </div>
          )}
          {updateSubscriptionResponse?.data?.detail?.detail && (
            <div className="">
              <div className="cd-err-msg">
                {updateSubscriptionResponse?.data?.detail?.detail[0]?.msg}
              </div>
            </div>
          )}
          <div>
            <Button
              className="cd-btn"
              onClick={updateSubscription}
              disabled={isDisabled}
              title={isDisabled ? "Please fill all inputs to enable" : "Submit"}
            >
              {" "}
              {t("update")}{" "}
            </Button>
          </div>
        </Card1>
      </DashboardWrapper>
    </div>
  );
};

export default AddSubscription;
